
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Data from "@/data/Production-history/Add.json";

export default defineComponent({
  name: "overview",
  components: {},
  data(): {
    DetailType: string;
    DetailNumber: string;
    Data: any;
    Type: string;
  } {
    return {
      DetailType: "",
      DetailNumber: "",
      Data: Data,
      Type: "",
    };
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("歷史生產履歷", ["生產履歷溝通數位化系統"]);
    });
  },
});
